import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  template: `
    <header>
      <ng-content></ng-content>
    </header>
    <!-- <header class="header">
      <app-navigation></app-navigation>
    </header> -->
  `,
  styles: [
    `
      @use 'variables' as variables;
      header {
        background-color: #fff;
      }
    `,
  ],
})
export class HeaderComponent {}
