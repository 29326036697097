<!-- ナビゲーション -->
<!-- <p>ログイン状態: {{ isLoggedIn$ | async }}</p> -->
<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light"> -->
<nav class="navbar-flex">
  <div class="logo">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/logo/logo_1.png" />
    </a>
    <!-- <a class="navbar-brand" routerLink="/">{{ service_name }}</a> -->
  </div>
  <!-- 
  <div class="search-bar">
    <input type="text" placeholder="Search" />
    <button>Search</button>
  </div> -->

  <div>
    <!-- <ng-container *ngIf="loggedInUser?.company_name">
      <div>
        {{ loggedInUser?.company_name }}
      </div>
      <div>
        <button (click)="onLogout()" class="navbar-button">ログアウト</button>
        <a routerLink="/mypage/dashboard/top" class="navbar-button">マイページ</a>
      </div>
    </ng-container> -->
    <div>
      <ng-container
        *ngIf="loggedInUserInfo$ | async as userInfo; else notLoggedIn"
      >
        <div *ngIf="userInfo?.is_logged_in; else notLoggedIn">
          <!-- ユーザーがログインしている場合に表示する内容 -->
          <div class="company-name">member_id:{{ userInfo.member_id }}</div>
          <div>
            <a (click)="onLogout()" class="navbar-link">ログアウト</a>
            <!-- <button (click)="onLogout()" class="navbar-button">ログアウト</button>
            <a routerLink="/mypage/dashboard/top" class="navbar-button">マイページ</a> -->
          </div>
        </div>
      </ng-container>
      <ng-template #notLoggedIn>
        <!-- ユーザーがログインしていない場合に表示する内容 -->
        <div class="navbar-links-wrapper">
          <a routerLink="/auth/login" class="navbar-link">ログイン</a>
          <a routerLink="/auth/register" class="navbar-link">ユーザ登録</a>
          <!-- <button routerLink="/auth/login" class="">ログイン</button> -->
          <!-- <button routerLink="/auth/register" class="navbar-button">ユーザ登録</button> -->
        </div>
      </ng-template>
    </div>

    <!-- ログインしていない場合に表示する内容 -->
    <!-- <ng-container *ngIf="(isLoggedIn$ | async) === false">
      <div class="navbar-links">
        <button routerLink="/auth/login" class="">ログイン</button>
        <button routerLink="/auth/register" class="navbar-button">ユーザ登録</button>
      </div>
    </ng-container> -->
  </div>
</nav>
