import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // すべてのリクエストに withCredentials: true を設定
    const modifiedReq = req.clone({
      withCredentials: true,
    });

    // 開発環境の場合のみログを出力
    if (environment.enableVerboseLogging) {
      console.log(
        'CredentialsInterceptor: Setting withCredentials to true for',
        req.url
      );
    }

    return next.handle(modifiedReq).pipe(
      tap(
        (_event: any) => {},
        (error) => {
          // エラーが発生した場合のログ（開発環境のみ）
          if (environment.enableVerboseLogging) {
            console.error(
              'CredentialsInterceptor: Error occurred for',
              req.url,
              error
            );
          }
        }
      )
    );
  }

  /*
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
  }
  */
}
