import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/header/navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { MobileFooterNavigationComponent } from './components/footer/mobile-footer-navigation/mobile-footer-navigation.component';
import { MatIconModule } from '@angular/material/icon';
import { PcFooterComponent } from './components/pc-footer/pc-footer.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    MobileFooterNavigationComponent,
    PcFooterComponent,
  ],
  imports: [CommonModule, RouterModule, MatIconModule],
  exports: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    PcFooterComponent,
    MobileFooterNavigationComponent,
  ],
})
export class CoreModule {}
