/**
 * ヘッダーに組み込むナビゲーション
 */

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

// import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  // =======================
  //
  // =======================
  service_name: string = `${environment.service_name}`;
  // JFAAコンペティション(仮)

  /*
  コンポーネントのテンプレート内でObservableオブジェクトのプロパティに直接アクセスしようとしているために発生しています。Angularのテンプレートでは、Observableから直接データを取り出すことはできません。代わりに、コンポーネント内でObservableを購読し、その結果をコンポーネントのプロパティに格納する必要があります。
  */
  // loggedInUser: any | null = null;
  // loggedInUser: User | null = null;
  // private subscription: Subscription = new Subscription();
  // AuthServiceからログイン状態を取得する
  // isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  // AuthServiceからログイン中のユーザの状態を取得する
  loggedInUserInfo$: Observable<any | null> =
    this.authService.loggedInUserInfo$;

  // isLoggedIn: boolean = false;
  last_name: string | null = null; // 初期状態はnull
  first_name: string | null = null; // 初期状態はnull
  isLoading: boolean = true; // last_nameとfirst_nameが取得済みか保存
  public apiRoot = `${environment.apiRoot}/api/`;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    // サービス側のログイン状態を取得するメソッドを実行(サービス内の会員情報loggedInUserInfo$も更新される)
    this.authService.getLoggedInUserInfo().subscribe();

    // ログイン中のユーザ情報を購読する
    /*
    this.subscription.add(
      this.authService.loggedInUserInfo$.subscribe((user) => {
        this.loggedInUser = user;
      })
    );
    */

    // デバッグ用: userInfoの内容をコンソールに出力
    // this.loggedInUserInfo$.subscribe(userInfo => {
    //   console.log('User Info:', userInfo);
    // });
  }

  onLogout() {
    // AuthServiceのlogoutメソッドを呼び出す
    this.authService.logout(
      () => {
        // ログアウト成功時の処理
        console.log('onLogout : Logged out successfull.');
        this.authService.getLoggedInUserInfo().subscribe(); // ログイン状態をチェックして新しいセッションIDを発行する
        this.router.navigate(['/']);
      },
      (error) => {
        // ログアウト時のエラー処理
        console.error('Error while logging out:', error);
        // ユーザーにエラーメッセージを表示
        alert('Logout failed. Please try again.');
      }
    );
  }
}
