/**
 * ログイン状態をBehaviorSubjectで管理するためのサービス
 *
 *
 */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, map, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { User } from '../models/user.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private csrfToken: string | null = null;

  // ========================================
  // APIサーバとの通信に使用する情報
  // ========================================
  public apiRoot = `${environment.apiRoot}`;
  private headers = new HttpHeaders({
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Content-Type': 'application/json',
  });
  // ========================================

  /* **********************************************
   * BehaviorSubject を使用してログイン状態を管理する
   * インスタンスをprivateにすることで、外部から直接.next()を呼び出すことができなくなり、データの変更がサービスのメソッドを通じてのみ行われる
   ***********************************************/
  // ========================================
  // ユーザ情報(ユーザ名、ユーザIDなど)を保持する
  // ========================================
  private loggedInUserInfoSubject: BehaviorSubject<any | null> =
    new BehaviorSubject<any | null>(null);
  // private loggedInUserInfoSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public loggedInUserInfo$ = this.loggedInUserInfoSubject.asObservable();

  constructor(private http: HttpClient) {}

  // CSRFトークンを取得
  getToken() {
    return this.csrfToken;
  }

  // JSONオブジェクトが空かどうかのチェック
  isJsonObjectEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  // 文字列が数字のみを含むかどうかをチェック
  isOnlyDigits(str: string): boolean {
    return /^\d+$/.test(str);
  }

  /**
   * 文字列がアルファベットと数字のみで構成されているかをチェック
   * @param str チェック対象の文字列
   * @returns アルファベットと数字のみで構成されている場合はtrue, それ以外はfalse
   */
  isAlphanumeric(str: string): boolean {
    return /^[A-Za-z0-9]+$/.test(str);
  }

  /**
   * ログイン中のユーザ情報(ユーザ名など)を取得する
   * @returns boolean
   */
  getLoggedInUserInfo(): Observable<boolean> {
    console.log('getLoggedInUserInfo()が呼ばれました');
    return this.http
      .get<{ member_id: number; member_name: string }>(
        `${this.apiRoot}/companies/public/read/read-get-logged-in-user-info.php`
      )
      .pipe(
        map((response: any) => {
          const isLoggedIn = response.record?.is_logged_in;
          console.log('isLoggedIn = ', isLoggedIn);
          // =============================================
          // ログイン状態を保持する BehaviorSubject を更新する
          // =============================================
          // this.isLoggedInSubject.next(isLoggedIn);
          // member_id に数字が入っていたらログインしているとみなす
          // const isLoggedIn = typeof response.member_id === 'number';
          // console.log('getLoggedInUserInfo::isLoggedIn = ' + isLoggedIn);
          if (isLoggedIn) {
            // 新しいUserオブジェクトの代わりにレスポンスのオブジェクトをそのまま使用
            this.loggedInUserInfoSubject.next({
              ...response?.record,
              // member_id: response.member_id,
              // member_name: response.member_name,
              // is_logged_in: isLoggedIn
            });
            console.log(
              'this.loggedInUserInfoSubject.getValue() = ',
              this.loggedInUserInfoSubject.getValue()
            );
          } else {
            // ログインしていなければ会員情報をクリア
            this.loggedInUserInfoSubject.next(null);
          }
          return isLoggedIn;
        }),
        catchError((error) => {
          // ==================
          // エラー発生時の処理
          // ==================
          console.error('ログイン状態確認中にエラーが発生しました', error);

          this.loggedInUserInfoSubject.next(null); // 会員情報をクリア
          return of(false); // エラー発生時にもObservable<boolean>を返す
        })
      );
  }

  /**
   * ユーザ登録用のAPIにデータを送信する
   * @param data
   * @param onSuccess
   * @param onError
   */
  register(
    data: any,
    onSuccess: (message: string) => void, // 成功時のコールバック関数
    onError: (error: any) => void // エラー時のコールバック関数
  ) {
    // HTTP POSTリクエストを使用してユーザ登録試行
    this.http
      .post(`${this.apiRoot}/auth/register.php`, data, {
        headers: this.headers,
      })
      .subscribe(
        // POSTリクエストが成功した場合
        (response: any) => {
          // サーバのレスポンスに 'message' が含まれている場合（登録成功）
          if (response.message) {
            // コンソールに成功メッセージを表示
            console.log('Registration successful', response);

            // 成功時のコールバック関数を実行
            onSuccess(response);
            // onSuccess(response.message);
          }
          // サーバのレスポンスに 'errors' が含まれている場合（登録失敗）
          else if (response.errors) {
            // コンソールにエラーメッセージを表示
            console.error('AuthService : Registration error:', response);

            // エラー時のコールバック関数を実行
            onError(response);
            // onError(response.error);
          }
        },
        // POSTリクエストが失敗した場合（ネットワークエラーなど）
        (error: any) => {
          // コンソールにエラーメッセージを表示
          console.error('Registration error:', error);

          // エラー時のコールバック関数を実行
          onError(error);
        }
      );
  }

  /**
   * 新規会員登録後、本部での承認に必要な追加の会社情報を登録するAPIにデータを送信するメソッド
   * @param data
   * @returns
   */
  registerAdditionalMemberInfo(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiRoot}/companies/authenticated/update/auth-update-member-info.php`,
        data,
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('AuthService : Registration error:', error);
    return throwError(error);
  }

  /**
   * 新規会員登録後、本部での承認に必要な請求担当の情報を登録するAPIにデータを送信するメソッド
   * @param data
   * @param onSuccess
   * @param onError
   */
  registerAdditionalBillingRepresentativeInfo(
    data: any,
    onSuccess: (message: string) => void, // 成功時のコールバック関数
    onError: (error: any) => void // エラー時のコールバック関数
  ) {
    // HTTP POSTリクエストを使用してユーザ登録試行
    this.http
      .post(
        `${this.apiRoot}/company_billing_representatives/authenticated/create-or-update/create-or-update-billing-representative-info.php`,
        data,
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .subscribe(
        // POSTリクエストが成功した場合
        (response: any) => {
          // サーバのレスポンスに 'message' が含まれている場合（登録成功）
          if (response.message) {
            console.log(
              'AuthService : create-or-update-billing-representative-info.php successful',
              response
            );
            onSuccess(response); // 成功時のコールバック関数を実行
            // onSuccess(response.message);
          }
          // サーバのレスポンスに 'error' が含まれている場合（登録失敗）
          else if (response.errors) {
            // コンソールにエラーメッセージを表示
            console.error(
              'AuthService : create-or-update-billing-representative-info.php error:',
              response
            );

            // エラー時のコールバック関数を実行
            onError(response);
            // onError(response.error);
          }
        },
        // POSTリクエストが失敗した場合（ネットワークエラーなど）
        (error: any) => {
          // コンソールにエラーメッセージを表示
          console.error('AuthService : Registration error:', error);

          // エラー時のコールバック関数を実行
          onError(error);
        }
      );
  }

  /**
   * 新規会員登録後、本部での承認に必要な管理者の情報を登録するAPIにデータを送信するメソッド
   * @param data
   * @param onSuccess
   * @param onError
   */
  registerAdditionalAdministratorInfo(
    data: any,
    onSuccess: (message: string) => void, // 成功時のコールバック関数
    onError: (error: any) => void // エラー時のコールバック関数
  ) {
    // HTTP POSTリクエストを使用してユーザ登録試行
    this.http
      .post(
        `${this.apiRoot}/company_administrators/authenticated/create-or-update/create-or-update-administrator-info.php`,
        data,
        {
          headers: this.headers,
        }
      )
      .subscribe(
        // POSTリクエストが成功した場合
        (response: any) => {
          // サーバのレスポンスに 'message' が含まれている場合（登録成功）
          if (response.message) {
            // コンソールに成功メッセージを表示
            console.log(
              'AuthService : registerAdditionalBillingRepresentativeInfo successful',
              response
            );

            // 成功時のコールバック関数を実行
            onSuccess(response);
            // onSuccess(response.message);
          }
          // サーバのレスポンスに 'error' が含まれている場合（登録失敗）
          else if (response.errors) {
            // コンソールにエラーメッセージを表示
            console.error(
              'AuthService : registerAdditionalBillingRepresentativeInfo error:',
              response
            );

            // エラー時のコールバック関数を実行
            onError(response);
            // onError(response.error);
          }
        },
        // POSTリクエストが失敗した場合（ネットワークエラーなど）
        (error: any) => {
          // コンソールにエラーメッセージを表示
          console.error('AuthService : Registration error:', error);

          // エラー時のコールバック関数を実行
          onError(error);
        }
      );
  }

  login(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiRoot}/auth/login.php`, credentials, {
        headers: this.headers,
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        tap((response: HttpResponse<any>) => {
          const csrfToken = response.headers.get('X-CSRF-Token');
          if (csrfToken) {
            // this.csrfService.setToken(csrfToken);
          } else {
            console.warn('X-CSRF-Token header not found');
          }
        }),
        map((response) => response.body),
        catchError(this.handleError)
      );
  }

  /*
  login(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiRoot}/auth/login.php`, credentials, {
        headers: this.headers,
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        tap((response: HttpResponse<any>) => {
          const csrfToken = response.headers.get('X-CSRF-Token');
          if (csrfToken) {
            this.csrfService.setToken(csrfToken);
          }
        }),
        map((response) => response.body),
        catchError(this.handleError)
      );
  }
  */

  /**
   * ログアウト用APIにPOSTリクエストを送信してログアウト処理を行う
   * @param onSuccess
   * @param onError
   */
  logout(onSuccess: () => void, onError: (error: any) => void) {
    // HTTP POSTリクエストを使用してサーバーにログアウトをリクエスト
    this.http
      .post(
        `${this.apiRoot}/auth/logout.php`,
        {},
        { headers: this.headers, withCredentials: true }
      )
      .subscribe(
        (response) => {
          // レスポンスが成功した場合の処理

          // ユーザーのログイン状態を更新
          this.loggedInUserInfoSubject.next(null); // 会員情報を空にする
          // コンソールに成功メッセージを表示
          console.log('logout()s : Logged out successfull.', response);

          // 成功時のコールバック関数を実行
          onSuccess();
        },
        (error) => {
          // リクエスト中にエラーが発生した場合の処理

          // コンソールにエラーメッセージを表示
          console.error('Logout error:', error);

          // エラー時のコールバック関数を実行
          onError(error);
        }
      );
  }

  /**
   * 会員のワンタイムパスワードを更新するメソッド
   * @param email
   * @param onSuccess
   * @param onError
   */
  requestOTP(
    email: string,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
  ): void {
    this.http
      .post(
        `${this.apiRoot}/auth/update/update-otp.php`,
        { email },
        { headers: this.headers }
      )
      .subscribe(
        (response) => {
          onSuccess(response); // 成功時のコールバック関数を呼び出す
          console.log('requestOTP: ', response);
        },
        (error) => {
          onError(error); // エラー時のコールバック関数を呼び出す
          console.log('requestOTP: ', error);
        }
      );
  }
}
