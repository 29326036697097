import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-footer-navigation',
  template: `
    <a routerLink="/" class="nav-button home-button">
      <mat-icon>home</mat-icon>
    </a>
    <a routerLink="/discover" class="nav-button discover-button">
      <mat-icon>search</mat-icon>
    </a>
    <a routerLink="/upload" class="nav-button add-button">
      <mat-icon>add_circle_outline</mat-icon>
    </a>
    <a routerLink="/inbox" class="nav-button inbox-button">
      <mat-icon>notifications</mat-icon>
    </a>
    <a routerLink="/mypage" class="nav-button profile-button">
      <mat-icon>person_outline</mat-icon>
    </a>
  `,
  styles: [
    `
      // モバイル用ナビゲーション
      /*
          .bottom-nav {
        position: fixed; // 画面下に固定
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem 0;
        background-color: #2d3748;
        z-index: 20;
        height: 60px;フッターナビゲーションの高さを明示的に設定 
        */
      a {
        color: white;
      }
      svg {
        stroke: white;
      }
    `,
  ],
  // templateUrl: './mobile-footer-navigation.component.html',
  // styleUrls: ['./mobile-footer-navigation.component.scss'],
})
export class MobileFooterNavigationComponent {}
