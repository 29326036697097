import { Component } from '@angular/core';

@Component({
  selector: 'app-pc-footer',
  template: `
    <footer class="pc-footer">
      <small>&copy; 2024 VtoV</small>
    </footer>
  `,
  styles: [
    `
      .pc-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        background-color: #f8f9fa;
      }

      @media (max-width: 768px) {
        .pc-footer {
          display: none;
        }
      }
    `,
  ],
})
export class PcFooterComponent {}
